import type { OcsPatchEntryInput } from 'owa-graph-schema';
import { lazyWriteOcsSettings } from 'owa-ocs-service-gql';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { QUICK_STEPS_SETTING_NAME } from '../constants';
import type { MailboxInfo } from 'owa-client-types';
import { logUsage } from 'owa-analytics';

export default function saveQuickStepsToService(mailboxInfo: MailboxInfo, valueToSave: string) {
    const ocsQuickStepsPatch: OcsPatchEntryInput = {
        name: QUICK_STEPS_SETTING_NAME,
        value: valueToSave,
        type: 'JSON',
        scope: getAccountScopeUserSettings(mailboxInfo).SessionSettings?.UserEmailAddress || '',
        itemClass: 'RoamingSetting',
    };
    logUsage('QS_Import_SaveToServiceCalled');
    return lazyWriteOcsSettings.importAndExecute(
        mailboxInfo,
        'ACCOUNT',
        [ocsQuickStepsPatch],
        'setMailQuickSteps' /* actionName */
    );
}
